import { Controller } from 'stimulus';
import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper';
Swiper.use([Autoplay, EffectFade, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination'];
  swiper = null;

  connect() {
    this.initHeroSlider();
    this.initIntersectionObserver();
  }

  initHeroSlider() {
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: 'fade',
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
    });
  }

  initIntersectionObserver() {
    const options = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.swiper.autoplay.start();
        } else {
          this.swiper.autoplay.stop();
        }
      });
    }, options);

    observer.observe(this.sliderTarget);
  }
}
